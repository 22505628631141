/* @jsx jsx */
import { jsx } from "theme-ui";
import tw, { theme } from "twin.macro";
import CardContent from "./CardContent";

interface CardProps {
  title: string | JSX.Element;
}

const Card: React.FC<CardProps> = ({ children, ...props }) => (
  <div
    sx={{
      ...tw`w-full border border-gray-300 rounded-lg flex flex-col overflow-hidden `,
      fontFamily: "Roboto",
    }}
  >
    {props.title && (
      <div>
        <div
          sx={{
            ...tw`font-bold text-xl text-gray-700 mt-5 mb-3 px-5`,
          }}
        >
          {props.title}
        </div>
      </div>
    )}
    {children}
  </div>
);
export default Card;
