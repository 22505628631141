/* @jsx jsx */
import { jsx } from "theme-ui";
import tw, { theme } from "twin.macro";

const CardContent: React.FC = ({ children }) => (
  <div sx={{ ...tw`px-5 text-base text-gray-600 leading-snug pb-5` }}>
    {children}
  </div>
);

export default CardContent;
