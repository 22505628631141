/* @jsx jsx */
import { jsx, Spinner } from "theme-ui";
import tw from "twin.macro";
import { Layout } from "../../../components/Layout";
import SEO from "../../../components/Seo";
import { Fragment, useState } from "react";
import { ReactComponent as BellOutline } from "heroicons/outline/bell.svg";
import { ReactComponent as BellSolid } from "heroicons/solid/bell.svg";
import { Flex } from "../../../components/Flex";
import CardContent from "../../../components/CardContent";
import CardFooter from "../../../components/CardFooter";
import Card from "../../../components/SettingsCard";
import FooterButton from "../../../components/SettingsButton";
import { validateEmail } from "../../../utils";
import InputField from "../../../components/InputField";
import ProgressBar from "../../../components/ProgressBar";
import Lozenge from "../../../components/Lozenge";
import InfoText from "../../../components/InfoText";
import {
  useUpdateProjectMutation,
  useGetProjectQuery,
  Project,
  useGetFeedbackQuery,
  useGetCheckoutSettingsUrlMutation,
  useInviteUserToProjectMutation,
  useToggleEmailFeedbackNotificationsMutation,
  useGetCurrentUserQuery,
} from "../../../graphql/queries/_generated";
import { useCheckoutHandler } from "../../../components/Checkout";
import Sidebar, {
  SidebarButton,
  SidebarGroup,
  SidebarTitle,
} from "../../../components/Sidebar";
import { colorMap } from "../../../../utils";
import { ReactComponent as ChevronLeft } from "heroicons/outline/chevron-left.svg";
import { Link } from "gatsby";

const ListItem: React.FC<{}> = ({ children }) => (
  <div
    sx={{
      ...tw`flex justify-between px-5 py-3 text-gray-700 border-b border-gray-300 last:border-b-0`,
    }}
  >
    {children}
  </div>
);

type OptionCategory = "Team" | "General";
const Settings = ({ project }: { project: Project }) => {
  const [activeOption, setActiveOption] = useState<OptionCategory>("General");
  const [addMemberInputText, setAddMemberInputText] = useState<string>("");
  const [inviting, setInviting] = useState<boolean>(false);
  const [projectNameInputText, setProjectNameInputText] = useState<string>(
    project.name
  );
  const [deleteChecked, setDeleteChecked] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);

  const [{ data }] = useGetFeedbackQuery({
    variables: {
      projectId: project.id,
    },
  });
  const [{ data: currentUserData }] = useGetCurrentUserQuery();
  const {
    handleClick: handleCheckoutClick,
    fetching: fetchingCheckoutClickHandler,
  } = useCheckoutHandler(project.id);
  const [_, updateProject] = useUpdateProjectMutation();
  const [__, getCheckoutSettingsUrl] = useGetCheckoutSettingsUrlMutation();
  const [___, inviteUserToProject] = useInviteUserToProjectMutation();
  const [
    { fetching: toggleEmailFeedbackNotificationsFetching },
    toggleEmailFeedbackNotifications,
  ] = useToggleEmailFeedbackNotificationsMutation();

  return (
    <div
      sx={{
        mb: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <div sx={tw`flex items-center justify-between mt-8 mb-16`}>
          <Link
            to={`/app/${project.id}`}
            sx={tw`flex items-center w-16 mt-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800`}
          >
            <ChevronLeft sx={tw`w-5`} />
            <span sx={tw`ml-1`}>Back</span>
          </Link>
          <h1
            sx={{
              fontFamily: "PT Serif",
              lineHeight: 1.2,
              textAlign: "center",
              fontSize: 4,
            }}
          >
            Settings
          </h1>
          <span sx={tw`w-16`} />
        </div>
        <div
          sx={{
            flex: 1,
            display: "grid",
            gap: [2, 2, 3, 5, 5],
            gridTemplateColumns: ["1fr", "1fr", "2fr 5fr", "2fr 5fr"],
          }}
        >
          <div>
            <Sidebar>
              <SidebarGroup>
                <SidebarTitle>Settings</SidebarTitle>
                <SidebarButton
                  style="none"
                  active={activeOption === "General"}
                  onClick={() => {
                    setActiveOption("General");
                  }}
                  text="General"
                  colors={colorMap.other}
                ></SidebarButton>
                <SidebarButton
                  style="none"
                  active={activeOption === "Team"}
                  onClick={() => {
                    setActiveOption("Team");
                  }}
                  text="Team"
                  colors={colorMap.other}
                ></SidebarButton>
              </SidebarGroup>
            </Sidebar>
          </div>
          {activeOption === "Team" && (
            <div>
              <Card title="Your Team">
                <div>
                  {project.usersConnection.edges.map((userEdge) => (
                    <ListItem>
                      <span sx={tw`flex items-center`}>
                        {userEdge.node.email}

                        {currentUserData?.currentUser?.id &&
                          currentUserData.currentUser.id ===
                            userEdge.node.id && (
                            <button
                              onClick={() => {
                                toggleEmailFeedbackNotifications({
                                  userId: userEdge.node.id,
                                  projectId: project.id,
                                });
                              }}
                              disabled={
                                toggleEmailFeedbackNotificationsFetching
                              }
                            >
                              {userEdge.emailFeedbackNotifications ? (
                                <BellSolid sx={tw`w-5 ml-2`} />
                              ) : (
                                <BellOutline sx={tw`w-5 ml-2`} />
                              )}
                            </button>
                          )}
                      </span>
                      <span sx={tw`text-gray-500`}>Member</span>
                    </ListItem>
                  ))}
                  {project?.invitations?.map((invitation) => (
                    <ListItem>
                      <span>{invitation.email}</span>
                      <span sx={tw`text-gray-500`}>Invited</span>
                    </ListItem>
                  ))}
                </div>
                <CardFooter
                  style={"default"}
                  leftContent={
                    <InputField
                      placeholder="colleague@company.com"
                      inputValue={addMemberInputText}
                      onChange={(v) => {
                        setValidEmail(validateEmail(v));
                        setAddMemberInputText(v);
                      }}
                      condensed={true}
                    ></InputField>
                  }
                  rightContent={
                    <FooterButton
                      buttonText={
                        inviting ? "Inviting Member..." : "Invite Member"
                      }
                      data-splitbee-event="Invite Member"
                      data-splitbee-event-location="settings"
                      disabled={inviting || !validEmail}
                      onButtonClick={() => {
                        setInviting(true);
                        inviteUserToProject({
                          email: addMemberInputText,
                          projectId: project.id,
                        })
                          .then(() => {
                            setAddMemberInputText("");
                            setValidEmail(validateEmail(""));
                          })
                          .finally(() => {
                            setInviting(false);
                          });
                      }}
                    ></FooterButton>
                  }
                ></CardFooter>
              </Card>
            </div>
          )}
          {activeOption === "General" && (
            <Flex gap={3} flexDirection="column">
              <Card title="Project Settings">
                <CardContent>
                  <Flex flexDirection="column" gap={3}>
                    <InputField
                      label="Name"
                      maxLength={32}
                      placeholder="Project Name"
                      inputValue={projectNameInputText}
                      onChange={(v) => setProjectNameInputText(v)}
                    />
                  </Flex>
                </CardContent>
                <CardFooter
                  style={"default"}
                  leftContent={
                    <div>
                      <span sx={{ ...tw`text-gray-500 select-none` }}>
                        Project Id: {}
                      </span>
                      <span>{project.id}</span>
                    </div>
                  }
                  rightContent={
                    <FooterButton
                      buttonText="Save"
                      disabled={
                        projectNameInputText != project.name ? false : true
                      }
                      onButtonClick={() => {
                        updateProject({
                          projectId: project.id,
                          name: projectNameInputText,
                        });
                      }}
                    ></FooterButton>
                  }
                ></CardFooter>
              </Card>
              <Card
                title={
                  <Flex
                    flexDirection="row"
                    justifyItems="center"
                    alignItems="center"
                    gap={2}
                  >
                    <span>Current Plan</span>
                    {project.isPaying ? (
                      <Lozenge
                        sx={tw`capitalize`}
                        text={project.paidPlan}
                        textColor="purple.6"
                        bgColor="purple.2"
                      />
                    ) : (
                      <Lozenge
                        text="Free"
                        textColor="teal.6"
                        bgColor="teal.2"
                      />
                    )}
                  </Flex>
                }
              >
                <CardContent>
                  {project.isPaying ? (
                    <Flex flexDirection="column" gap={3}>
                      <span>
                        To change your plan, edit your credit card information
                        or cancel your subscription click on{" "}
                        <span sx={{ ...tw`font-bold` }}>
                          Manage Subscription{" "}
                        </span>
                      </span>
                      <Flex gap={5}>
                        <InfoText text="Unlimited" label="Monthly Limit" />
                        <InfoText
                          text={`$${project.pricePerMonth / 100}/month`}
                          label="Cost"
                        />
                        <InfoText
                          text={
                            data?.feedback.length.toString() || "Loading..."
                          }
                          label="Collected Feedback"
                        />
                      </Flex>
                    </Flex>
                  ) : (
                    <Flex gap={3} flexDirection="column">
                      <Flex flexDirection="column" gap={2}>
                        <ProgressBar
                          percentage={
                            data ? (data.feedback.length / 25) * 100 : 0
                          }
                          label={
                            data
                              ? `${data?.feedback.length} / 25 feedback submissions`
                              : `Loading...`
                          }
                        ></ProgressBar>
                        <span sx={{ ...tw`font-bold pt-2` }}>
                          Upgrade to Pro ($19 / mo) to unlock:
                        </span>
                        <span sx={{ ...tw`text-gray-500` }}>
                          Unlimited feedback submissions
                        </span>
                        <span sx={{ ...tw`font-bold pt-2` }}>
                          Contact us about Enterprise to additionally unlock:
                        </span>
                        <span sx={{ ...tw`text-gray-500` }}>
                          Whitelabeling, service-level agreements and live chat
                          support
                        </span>
                      </Flex>
                    </Flex>
                  )}
                </CardContent>
                <CardFooter
                  style={"default"}
                  rightContent={
                    <FooterButton
                      disabled={fetchingCheckoutClickHandler}
                      buttonText={
                        project.isPaying
                          ? "Manage Subscription"
                          : "Upgrade to Pro"
                      }
                      data-splitbee-event={
                        project.isPaying
                          ? "Click Manage Subscription"
                          : "Click Upgrade"
                      }
                      data-splitbee-event-location="settings"
                      onButtonClick={async (evt) => {
                        if (project.isPaying) {
                          const { data } = await getCheckoutSettingsUrl({
                            projectId: project.id,
                            returnUrl: window.location.href,
                          });
                          if (data?.getCheckoutSettingsUrl) {
                            window.location.href = data.getCheckoutSettingsUrl;
                          }
                        } else {
                          handleCheckoutClick(evt);
                        }
                      }}
                    ></FooterButton>
                  }
                ></CardFooter>
              </Card>
              {/* <Card title="Delete this Project">
                <CardContent>
                  <span>
                    This action will remove all of this projects data including:
                    Collected Feedback and your projects billing subscription.
                  </span>
                </CardContent>
                <CardFooter
                  style={deleteChecked ? "danger" : "default"}
                  leftContent={
                    <Flex alignItems="center" gap={2}>
                      <CheckBox
                        checked={deleteChecked}
                        onClick={() => setDeleteChecked(!deleteChecked)}
                      ></CheckBox>
                      <span sx={{ ...tw`align-middle` }}>
                        I confirm the deletion of this Project
                      </span>
                    </Flex>
                  }
                  rightContent={
                    <FooterButton
                      buttonText="Delete Project"
                      disabled={deleteChecked ? false : true}
                      onButtonClick={() => {}}
                    ></FooterButton>
                  }
                ></CardFooter>
              </Card> */}
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

type PageProps = {
  params: {
    projectId: string;
  };
};

const SettingsPage = (props: PageProps) => {
  const [{ data, fetching, error }] = useGetProjectQuery({
    variables: { id: props.params.projectId },
    pollInterval: 5000,
    requestPolicy: "cache-and-network",
  });

  return (
    <Fragment>
      {fetching && <Spinner size={24} color="brandBlue.2" />}
      {data?.project && <Settings project={data.project} />}
      {error && <p>{error.message}</p>}
    </Fragment>
  );
};

export default (props: PageProps) => {
  return (
    <Layout minimalFooter centered={false}>
      <SEO title="Settings" description="Manage your projects' settings." />
      <SettingsPage {...props} />
    </Layout>
  );
};
