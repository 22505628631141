/* @jsx jsx */
import { MouseEvent } from "react";
import { jsx } from "theme-ui";
import tw, { theme } from "twin.macro";

const FooterButton: React.FC<{
  buttonText: string;
  disabled?: boolean;
  onButtonClick: (event: MouseEvent<HTMLButtonElement>) => void;
}> = ({ buttonText, disabled, onButtonClick,  ...rest }) => (
  <button
    disabled={disabled}
    aria-label={buttonText + " Button"}
    sx={{
      ...tw`bg-gray-600 text-white px-6 py-1 text-sm font-medium hover:bg-gray-700 `,
      borderRadius: 6,
      ":disabled": {
        ...tw`bg-white text-gray-400 cursor-not-allowed`,
      },
    }}
    onClick={(evt) => onButtonClick(evt)}
    {...rest}
  >
    {buttonText}
  </button>
);
export default FooterButton;
