exports.HELP_PAGES = {
  // General
  e7250790c9e7406491d3e7576eda542a: {
    slug: "/help/privacy",
  },
  "9f0f329a9b684a08b74e7f25b7a293e5": {
    slug: "/help/tos",
  },
  // Help center
  b7083cb082c943299c04fcfef9a06ff2: {
    slug: "/help",
  },
  "047873876fb44512a779d8c4a6a3d6d2": {
    slug: "/help/wordpress",
    description:
      "How to integrate a feedback widget into your WordPress website in less than one minute.",
  },
  "3946084c3db44d6fad46dd35830fac50": {
    slug: "/help/react",
    description:
      "How to integrate a feedback widget into your React app with our component",
  },
  eee78b18893f4b9abd3a2e5ce6ff9877: {
    slug: "/help/vue",
    description:
      "How to integrate a feedback widget into your Vue app with our component",
  },
  "330489a1b2d44e0597e4f2fba6b3f8ed": {
    slug: "/help/html",
    description:
      "How to integrate a feedback widget into your website in less than one minute",
  },
  "69dfaace901a46a0a26da72b5b06b749": {
    slug: "/help/headless",
    description:
      "How to build a custom feedback widget with the Feedback Fish API.",
  },
  efd623b76cfd4bd791956e420c61419f: {
    slug: "/help/localisation",
    description:
      "Our feedback widget supports 43 languages. Learn how to select the correct language for you!",
  },
  "11504b94394f462bbcf709dbd0f708f8": {
    slug: "/help/userid",
    description:
      "How to enable user identification so you always know who sent in feedback submissions.",
  },
  dd73820e59284f25bd1ea8c5e275b205: {
    slug: "/help/device",
    description: "How device identification on Feedback Fish works.",
  },
  d22759dc81ce495e92b0225480556221: {
    slug: "/help/team",
    description:
      "How to invite your team members to your Feedback Fish project",
  },
  "3677f7ff9a774b2c92b89214e8bdcd05": {
    slug: "/help/screenshot",
    description:
      "How the feedback widget allows your users to submit website screenshots with their feedback",
  },
  c0b410736cf7421c8c89cd0608fa65ed: {
    slug: "/help/bootstrap",
    description:
      "How to integrate a feedback widget into your Bootstrap website in less than one minute.",
  },
  f454cf2f9ac245fd985b76d126e90fbb: {
    slug: "/help/metadata",
    description:
      "How to attach arbitrary metadata to your feedback submissions.",
  },
  "955d4690079c45b6a6299742fd1954f8": {
    slug: "/help/free-plan-limits",
  },
};
