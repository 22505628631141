/* @jsx jsx */
import { jsx } from "theme-ui";
import tw, { theme } from "twin.macro";
import { Flex } from "./Flex";

const ProgressBar: React.FC<{ percentage: number; label: string }> = ({
  label,
  percentage,
}) => (
  <Flex flexDirection="column" gap={1}>
    <label sx={{ ...tw`text-gray-500` }}>{label}</label>
    <div sx={{ ...tw`w-full h-2 bg-gray-300 rounded-full relative` }}>
      <div
        sx={{
          ...tw`h-2 bg-gray-600 rounded-full absolute`,
          width: Math.min(percentage, 100) + "%",
        }}
      ></div>
    </div>
  </Flex>
);
export default ProgressBar;
