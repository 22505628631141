/* @jsx jsx */
import { jsx } from "theme-ui";
import tw, { theme } from "twin.macro";
import { Flex } from "./Flex";

const InfoText: React.FC<{ label: string; text: string }> = ({
  label,
  text,
}) => (
  <Flex flexDirection="column" gap={1}>
    <label sx={{ ...tw` text-gray-500 tracking-normal text-sm font-medium` }}>
      {label}
    </label>
    <span sx={{ ...tw` text-gray-600 text-lg font-normal` }}>{text}</span>
  </Flex>
);

export default InfoText;
