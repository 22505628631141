/* @jsx jsx */
import { jsx } from "theme-ui";
import tw from "twin.macro";

const Lozenge: React.FC<{
  text: string;
  bgColor?: string;
  textColor?: string;
}> = ({ text, textColor, bgColor, ...rest }) => (
  <div
    sx={{
      ...tw`bg-gray-300 text-gray-600 rounded-md flex justify-center`,
      height: "fit-content",
      bg: bgColor,
      color: textColor,
      lineHeight: 1,
    }}
    {...rest}
  >
    <span
      sx={{
        ...tw`text-base px-3 font-normal leading-none`,
        marginTop: "1px",
        paddingY: "3px",
      }}
    >
      {text}
    </span>
  </div>
);
export default Lozenge;
