import { ColorStyles } from "../types/settings";
import { HELP_PAGES } from "../help-pages";

export const getPrettyHelpUrl = (notionId: string): string => {
  return (
    HELP_PAGES[notionId.replace(/-/g, ``)]?.slug || notionId.replace(/-/g, ``)
  );
};

export const colorResolver = (color: ColorStyles) => {
  switch (color) {
    case "default":
      return { textColor: "gray.6", bgColor: "gray.2" };
    case "success":
      return { textColor: "green.7", bgColor: "green.1" };
    case "warning":
      return { textColor: "orange.7", bgColor: "orange.1" };
    case "danger":
      return { textColor: "red.7", bgColor: "red.1" };
  }
};

export const validateEmail = (mail: string) => {
  let isValid;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(String(mail).toLowerCase())) {
    isValid = true;
  } else {
    isValid = false;
  }

  return isValid;
};
