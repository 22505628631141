/* @jsx jsx */
import { jsx } from "theme-ui";
import tw, { theme } from "twin.macro";
import { ColorStyles } from "../../types/settings";
import { colorResolver } from "../utils";

interface CardFooterProps {
  leftContent?: JSX.Element | string;
  rightContent?: JSX.Element | string;
  style: ColorStyles;
}
const CardFooter: React.FC<CardFooterProps> = ({
  style = "default",
  leftContent,
  rightContent,
}) => (
  <div
    sx={{
      ...tw`w-full self-end border-t border-gray-300 py-3 px-5 flex justify-between items-center`,
      bg: colorResolver(style)?.bgColor,
      color: colorResolver(style)?.textColor,
    }}
  >
    <div sx={{ ...tw`w-full flex justify-start flex-grow` }}>{leftContent}</div>
    <div sx={{ ...tw`w-full flex justify-end flex-grow-0` }}>
      {rightContent}
    </div>
  </div>
);

export default CardFooter;
